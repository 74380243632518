import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import TestSwitcher from '../components/TestSwitcher';
import styles from '../styles/SoftSkillTest.module.css';

const SoftSkillTest = () => {
    const [question, setQuestion] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    useEffect(() => {
        fetchQuestion();
    }, []);

    const fetchQuestion = async () => {
        const token = getSessionToken();
        console.log(token);
        try {
            const response = await fetch('https://api.wanteed-job.com/questions/get-softskill?tunnel=false', {
                method: 'GET',
                headers: {
                    'session-key': `${token}`
                }
            });
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await response.json();
            setQuestion(data);
            setLoading(false);
        } catch (error) {
            setError('Failed to load question. Please try again. (You may have answered all questions)');
            setLoading(false);
        }
    };

    const handleAnswer = async (value) => {
        const token = getSessionToken();
        try {
            const response = await fetch('https://api.wanteed-job.com/questions/answer-softskill', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token,
                },
                body: JSON.stringify({
                    questionID: question.question_id,
                    value: value,
                    time: 0,
                }),
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            fetchQuestion(); // Fetch the next question
        } catch (error) {
            setError('Failed to submit answer. Please try again.');
        }
    };

    return (
        <div className={styles["softskill-test"]}>
            <TestSwitcher activeTest="softskills" />
            <h1 className={styles["test-title"]}>Soft Skill Test</h1>
            <p className={styles["test-description"]}>Cliquez sur une bulle pour indiquer quelle affirmation vous correspond le mieux.<br />La bulle au centre indique que vous êtes neutre.</p>
            {loading ? (
                <p className={styles["loading"]}>Loading...</p>
            ) : error ? (
                <p className={styles["error"]}>{error}</p>
            ) : (
                question && (
                    <div className={styles["question-container"]}>
                        <div className={styles["question-options"]}>
                            <p className={styles["question"]}>{question.question_a}</p>
                            <p className={styles["question"]}>{question.question_b}</p>
                        </div>
                        <div className={styles["scale"]}>
                            <span onClick={() => handleAnswer(-3)} className={styles["leftmost"]}></span>
                            <span onClick={() => handleAnswer(-2)} className={styles["left"]}></span>
                            <span onClick={() => handleAnswer(-1)} className={styles["left"]}></span>
                            <span onClick={() => handleAnswer(0)} className={styles["neutral"]}></span>
                            <span onClick={() => handleAnswer(1)} className={styles["right"]}></span>
                            <span onClick={() => handleAnswer(2)} className={styles["right"]}></span>
                            <span onClick={() => handleAnswer(3)} className={styles["rightmost"]}></span>
                        </div>
                    </div>
                )
            )}
        </div>
    );
};

export default SoftSkillTest;
