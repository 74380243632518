import React, { useState, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from './Button';
import { AuthContext } from './AuthContext';
import { getSessionToken, removeSessionToken } from '../utils/Auth';
import styles from '../styles/Navbar.module.css';
import logo from "../assets/logo.png";

function Navbar() {
    const { isLoggedIn, setIsLoggedIn } = useContext(AuthContext);
    const navigate = useNavigate();
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    // On page load, check if the user is logged in
    useEffect(() => {
        getSessionToken() ? setIsLoggedIn(true) : setIsLoggedIn(false)
    });

    const handleLogout = () => {
        removeSessionToken();
        setIsLoggedIn(false);
        navigate('/login');
    };

    const toggleMenu = () => {
        setIsMenuOpen(!isMenuOpen);
    };

    return (
        <nav className={styles["navbar"]}>
            <img src={logo} alt="Logo" className={styles["logo"]} onClick={() => navigate('/')} />
            <div className={`${styles["menu-icon"]} ${isMenuOpen ? styles['open'] : ''}`} onClick={toggleMenu}>
                <div className={styles["burger"]}></div>
            </div>
            <ul className={`${styles["nav-links"]} ${isMenuOpen ? styles['active'] : ''}`}>
                <li> <a href="/jobs" className={styles["navlink"]}>Explorer les offres</a></li>
                <li> <a href="/admin" className={styles["navlink"]}>Recruter sur Wanteed</a></li>
                {isLoggedIn && <li><Button text="Mon Profil" link="/profile" variant="secondary" /></li>}
                {isLoggedIn ? (
                    <li><Button text="Déconnexion" onClick={handleLogout} variant='inverted'/></li>
                ) : (
                    <li><Button text="Connexion" link="/login" variant='inverted'/></li>
                )}
            </ul>
        </nav>
    );
}

export default Navbar;
