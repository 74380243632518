import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/List.module.css';

const ExperienceList = (deletable) => {
    const [experiences, setExperiences] = useState([]);

    const sessionkey = getSessionToken();

    useEffect(() => {
        fetch('https://api.wanteed-job.com/experience/get',
            {
                method: 'GET',
                headers: {
                    'session-key': sessionkey
                }
            })
            .then(response => response.json())
            .then(data => setExperiences(data))
            .catch(error => console.error('Error fetching experiences:', error));
    }, [sessionkey]);

    const handleDelete = async (experienceId) => {
        try {
            const response = await fetch('https://api.wanteed-job.com/experience/remove', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionkey
                },
                body: JSON.stringify({ experience_id: experienceId })
            });
            if (response.ok) {
                setExperiences(prevExperiences => prevExperiences.filter(exp => exp.experience_id !== experienceId));
            } else {
                console.error('Failed to delete experience');
            }
        } catch (error) {
            console.error('Error deleting experience:', error);
        }
    };

    return (
        <div className={styles["list"]}>
            {experiences.map(experience => (
                <div key={experience.experience_id} className={styles["list-item"]}>
                    <div className={styles["item-details"]}>
                        <h3>{experience.profession} at {experience.company}</h3>
                        <p>{experience.domain} - {experience.duration} years</p>
                        <p>{experience.description}</p>
                    </div>
                    {deletable && <button className={styles["delete-button"]} onClick={() => handleDelete(experience.experience_id)}>Delete</button>}
                </div>
            ))}
        </div>
    );
};

export default ExperienceList;
