import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/List.module.css';

const HardSkillList = () => {
    const [hardSkills, setHardSkills] = useState([]);

    const sessionkey = getSessionToken();

    // Fetch hard skills after experiences are loaded
    useEffect(() => {
        const fetchHardSkills = async () => {
            try {
                const response = await fetch('https://api.wanteed-job.com/hardskill/get', {
                    method: 'GET',
                    headers: {
                        'session-key': sessionkey
                    }
                });
                const data = await response.json();
                const hardskills = data["hardskills"];
                setHardSkills(hardskills);
            } catch (error) {
                console.error('Error fetching hard skills:', error);
            }
        };

        fetchHardSkills();
    }, [sessionkey]); // Dependency on experiences ensures this runs only after experiences are set

    const handleDelete = async (hardskill) => {
        try {
            const response = await fetch('https://api.wanteed-job.com/job-company/remove-hardskill', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionkey
                },
                body: JSON.stringify(hardskill)
            });
            if (response.ok) {
                setHardSkills(prevSkills => prevSkills.filter(skill => skill.hardskill !== hardskill.hardskill));
            } else {
                console.error('Failed to delete hard skill');
            }
        } catch (error) {
            console.error('Error deleting hard skill:', error);
        }
    };

    return (
        <div className={styles["list"]}>
            {hardSkills.length === 0 ? <p>No hard skills found</p> :
                hardSkills.map(skill => (
                    <div key={skill.hardskill} className={styles["list-item"]}>
                        <div className={styles["item-details"]}>
                            <h3>{skill.hardskill}</h3>
                            <p>{skill.type}</p>
                        </div>
                        <button className={styles["delete-button"]} onClick={() => handleDelete(skill)}>Delete</button>
                    </div>
                ))
            }
        </div>
    );
};

export default HardSkillList;
