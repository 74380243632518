// src/pages/Login.js
import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../components/AuthContext";
import { setSessionToken } from '../utils/Auth';
import Notification from "../components/Notification";
import styles from "../styles/Login.module.css";

function Login() {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthContext);
    const [error, setError] = useState(false);
    const [showPassword, setShowPassword] = useState(false); // State for toggling password visibility

    // Check if the user is already logged in
    useEffect(() => {
        if (AuthContext.isLoggedIn) {
            navigate('/');
        }
    }, [navigate]);

    const handleLogin = (e) => {
        e.preventDefault();
        fetch('https://api.wanteed-job.com/credential/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(
                {
                    email: email,
                    password: password
                }
            )
        })
            .then(async response => {
                if (!response.ok) {
                    setError("Invalid credentials");
                    return;
                }
                let responseJson = await response.json();
                console.log(responseJson);
                console.log("Login successful");
                setIsLoggedIn(true);
                setSessionToken(responseJson); // Store the token in cookies
                navigate('/jobs');
            }
            )
    };

    return (
        <div className={styles["loginCard"]}>
            <form onSubmit={handleLogin} className={styles["loginForm"]}>
                <h1>Connexion</h1>
                <p>Bienvenue sur Wanteed !</p>
                <div className={styles["errorMessage"]} style={{ display: error ? "block" : "none" }}>
                    Mail ou mot de passe incorrect
                </div>
                <div className={styles["inputRow"]} style={{ marginTop: "50px" }}>
                    <input
                        type="email"
                        id="email"
                        name="email"
                        placeholder="Email"
                        className={styles["inputGroup"]}
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    /><br />
                </div>
                <div className={styles["inputRow"]}>
                    <input
                        type={showPassword ? "text" : "password"}
                        id="password"
                        name="password"
                        placeholder="Password"
                        className={styles["inputGroup"]}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <br />
                    <button
                        type="button"
                        className={styles["showPasswordButton"]}
                        onClick={() => setShowPassword(!showPassword)} // Toggle password visibility
                        style={{ marginLeft: "10px", marginTop: "0", height: "30px" }}
                    >
                        {showPassword ? "Cacher" : "Voir"}
                    </button>
                </div>
                <button type="submit">Se connecter</button>
                <p className={styles["bottom"]}>Pas encore de compte ? <a href="/register">Créer un compte</a></p>
                <p className={styles["bottom"]}>Mot de passe oublié ? <a href="/resetpassword">Réinitialiser le mot de passe</a></p>
            </form>
            {error && <Notification message={error} type="error" onClose={() => setError(null)} />}
        </div>
    );
}

export default Login;
