import React from "react";
import { useNavigate } from "react-router-dom";
import styles from "../styles/Register.module.css";
import { setSessionToken } from '../utils/Auth';
import { AuthContext } from "../components/AuthContext";
import { useContext } from "react";

function ValidateAccountPage() {

    const navigate = useNavigate();
    const { setIsLoggedIn } = useContext(AuthContext);

    // Get the email from the URL
    const urlParams = new URLSearchParams(window.location.search);
    const email = urlParams.get('email');

    const handleValidation = (e) => {
        e.preventDefault();

        fetch('https://api.wanteed-job.com/credential/validate-account', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ email: email, code: document.getElementById('validation_code').value }),
        })
            .then(response => {
                if (!response.ok) {
                    alert("Invalid code");
                    return;
                }
                return response.json();
            }).then(data => {
                console.log(data);
                console.log("Login successful");
                setIsLoggedIn(true);
                setSessionToken(data); // Store the token in cookies
                navigate('/');
            })
            .catch((error) => {
                console.error('Error:', error);
                alert('An error occurred.');
            });
    };


    return (
        <div className={styles["registerCard"]}>
            <div className={styles["registerForm"]}>
                <form>
                    <h1>Confirmez votre email</h1>
                    <p>Validez votre compte Wanteed en entrant le code de validation que vous avez reçu par email.</p>
                    <div className={styles["inputRow"]} style={{ marginTop: "50px" }}>
                        <input
                            type="text"
                            id="validation_code"
                            placeholder="Entrez le code de validation"
                            name="validation_code"
                        />
                    </div>
                    <div className={styles["inputRow"]}>
                        <button type="submit" onClick={handleValidation}>Valider</button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ValidateAccountPage;