import React, { useEffect, useState } from 'react';
import EditProfile from '../pages/EditProfile';
import { getSessionToken } from '../utils/Auth';
import styles from "../styles/Profile.module.css"

const Profile = () => {
    const [userData, setUserData] = useState(null);
    const [isEditing, setIsEditing] = useState(false);
    const sessionKey = getSessionToken();

    useEffect(() => {
        fetch('https://api.wanteed-job.com/user/get-information', {
            method: 'GET',
            headers: { 'session-key': sessionKey }
        })
            .then(response => response.json())
            .then(data => setUserData(data))
            .catch(error => console.error('Error fetching user data:', error));
    }, [sessionKey]);

    if (!userData) {
        return <div>Loading...</div>;
    }

    if (isEditing) {
        return <EditProfile userData={userData} />;
    }

    return (
        <div className={styles.profileContainer}>
            <h1 className={styles.profileTitle}>Mon profil</h1>
            <p className={styles.profileItem}><strong>Civilité:</strong> {userData.civility}</p>
            <p className={styles.profileItem}><strong>Pays:</strong> {userData.country}</p>
            <p className={styles.profileItem}><strong>Téléphone:</strong> {userData.phone}</p>
            <p className={styles.profileItem}><strong>Adresse:</strong> {userData.address}</p>
            <p className={styles.profileItem}><strong>Email:</strong> {userData.email}</p>
            <p className={styles.profileItem}><strong>Ville:</strong> {userData.city}</p>
            <p className={styles.profileItem}><strong>Newsletter:</strong> {userData.newsletter ? 'Abonné' : 'Non Abonné'}</p>
            <p className={styles.profileItem}><strong>Date de naissance:</strong> {userData.birth_date}</p>
            <button className={styles.editButton} onClick={() => setIsEditing(true)}>MODIFIER</button>
        </div>
    );
};

export default Profile;