// src/pages/HardSkillsPage.js
import React from 'react';
import HardSkillForm from '../components/HardSkillForm';
import HardSkillList from '../components/HardSkillList';
import ExperienceForm from '../components/ExperienceForm';
import ExperienceList from '../components/ExperienceList';
import TestSwitcher from '../components/TestSwitcher';
import styles from '../styles/HardSkillsPage.module.css';  // Utilisation d'un module CSS

const HardSkillsPage = () => {
    return (
        <div className={styles.hardSkillsPage}>
            <TestSwitcher activeTest="hardskills" />
            <div className={styles.container}>
                <h1>Vos hardskills</h1>
                <div className={styles.section}>
                    <h2>Importez votre CV</h2>
                    <p>Importez votre CV pour extraire vos hardskills automatiquement</p>
                    <input type="file" />
                    <button>Importer et analyser</button>
                </div>
                <div className={styles.section}>
                    <h2>Vos hardskills</h2>
                    <HardSkillList />
                </div>
                <div className={styles.section}>
                    <h2>Ajouter un hardskill manuellement</h2>
                    <HardSkillForm />
                </div>
                <div className={styles.section}>
                    <h2>Add New Experience</h2>
                    <ExperienceForm />
                </div>
                <div className={styles.section}>
                    <h2>Your Experiences</h2>
                    <ExperienceList />
                </div>
            </div>
        </div>
    );
};

export default HardSkillsPage;
