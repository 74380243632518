import React, { useState, useEffect } from 'react';
import styles from '../styles/Form.module.css';
import { getSessionToken } from '../utils/Auth';

const ExperienceForm = ({ onAddExperience }) => {
    const [formData, setFormData] = useState({
        domain_id: '',
        duration: 0,
        description: '',
        performance: '',
        appreciation: '',
        company: '',
    });
    const [domains, setDomains] = useState({});
    const [categories, setCategories] = useState([]);
    const sessionKey = getSessionToken();

    useEffect(() => {
        const fetchDomains = async () => {
            try {
                const response = await fetch('https://api.wanteed-job.com/job/get-domains', {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                });
                if (response.ok) {
                    const data = await response.json();
                    console.log('Fetched domains:', data);
                    setDomains(data);
                    setCategories(Object.keys(data));
                } else {
                    console.error('Failed to fetch domains');
                }
            } catch (error) {
                console.error('Error fetching domains:', error);
            }
        };

        fetchDomains();
    }, []);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch('https://api.wanteed-job.com/experience/add', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionKey
                },
                body: JSON.stringify(formData)
            });
            if (response.ok) {
                const newExperience = await response.json();
                onAddExperience(newExperience);
                setFormData({
                    domain_id: '',
                    duration: 0,
                    description: '',
                    performance: '',
                    appreciation: '',
                    company: '',
                });
            } else {
                console.error('Failed to add experience');
            }
        } catch (error) {
            console.error('Error adding experience:', error);
        }
    };

    return (
        <form className={styles["form"]} onSubmit={handleSubmit}>
            <div className={styles["form-group"]}>
                <label>Domaine :</label>
                <select name="domain_id" value={formData.domain_id} onChange={handleChange} required>
                    <option value="">Select a domain</option>
                    {categories.map(category => (
                        <optgroup key={category} label={category}>
                            {domains[category].map(domain => (
                                <option key={domain.id} value={domain.id}>{domain.profession}</option>
                            ))}
                        </optgroup>
                    ))}
                </select>
            </div>
            <div className={styles["form-group"]}>
                <label>Profession:</label>
                <input type="text" name="profession" value={formData.profession} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Durée (années):</label>
                <input type="number" name="duration" value={formData.duration} onChange={handleChange} required />

            </div>
            <div className={styles["form-group"]}>
                <label>Description:</label>
                <textarea name="description" value={formData.description} onChange={handleChange} required />
            </div>
            <div className="form-group">
                <label>Entreprise :</label>
                <input type="text" name="company" value={formData.company} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Appreciation :</label>
                <input type="text" name="appreciation" value={formData.appreciation} onChange={handleChange} required />
            </div>
            <div className={styles["form-group"]}>
                <label>Performance :</label>
                <input type="text" name="performance" value={formData.performance} onChange={handleChange} required />
            </div>
            <button type="submit" className={styles["button"]}>Add Experience</button>
        </form>
    );
};

export default ExperienceForm;
