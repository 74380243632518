import React, { useState, useEffect, useCallback } from 'react';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/AdminCompanyPage.module.css';

// Modal for adding a new job offer
import JobModal from '../components/JobModal';

import GenericToggleButton from "../components/GenericToggleButton";

const AdminCompanyPage = () => {
    const [companies, setCompanies] = useState([]);
    const [selectedCompany, setSelectedCompany] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const token = getSessionToken();
    const [expandedJobId, setExpandedJobId] = useState(null);

    const [users, setUsers] = useState([]);

    const [view, setView] = useState('Offres');

    const [emailToAdd, setEmailToAdd] = useState('');

    // Fetch jobs for the selected company

    const fetchJobs = useCallback(async (companyId) => {
        try {
            const response = await fetch(`https://api.wanteed-job.com/company/get-job?company_id=${companyId}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setJobs(data);
            } else {
                console.error('Failed to fetch jobs');
            }
        } catch (error) {
            console.error('Error fetching jobs:', error);
        }
    }, [token]);

    // Fetch companies associated with the user
    const fetchCompanies = useCallback(async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/company/get-user-companies', {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            });
            if (response.ok) {
                const data = await response.json();
                setCompanies(data);
                if (data.length === 1) {
                    setSelectedCompany(data[0]); // Auto-select if only one company
                    fetchJobs(data[0].company_id);
                }
            } else {
                console.error('Failed to fetch companies');
            }
        } catch (error) {
            console.error('Error fetching companies:', error);
        }
    }, [token, fetchJobs]);

    useEffect(() => {
        fetchCompanies();
    }, [fetchCompanies]);

    useEffect(() => {
        // Get users of selected company
        if (selectedCompany) {
            fetch(`https://api.wanteed-job.com/company/get-company-users?company_id=${selectedCompany.company_id}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                }
            })
                .then(response => response.json())
                .then(data => setUsers(data))
                .catch(error => console.error('Error fetching users:', error));
        }
    }, [selectedCompany, token]);

    // Handle company selection
    const handleCompanySelect = (event) => {
        const companyId = event.target.value;
        const company = companies.find(c => c.company_id === companyId);
        setSelectedCompany(company);
        fetchJobs(company.company_id);
    };

    // Handle search input change
    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value);
    };

    // Filter jobs based on the search term
    const filteredJobs = jobs.filter(job => job.title.toLowerCase().includes(searchTerm.toLowerCase()));

    // Open the modal to add a new job
    const openModal = () => setIsModalOpen(true);

    // Close the modal
    const closeModal = () => setIsModalOpen(false);

    const closeEditModal = () => setExpandedJobId(null);

    const deleteJob = async (jobId) => {
        try {
            const response = await fetch(`https://api.wanteed-job.com/company/remove-job`, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                },
                body: JSON.stringify({ company_id: selectedCompany.company_id, job_id: jobId })
            });
            if (response.ok) {
                fetchJobs(selectedCompany.company_id);
            } else {
                console.error('Failed to delete job');
            }
        } catch (error) {
            console.error('Error deleting job:', error);
        }
    }

    const toggleExpand = (jobId) => {
        if (expandedJobId === jobId) {
            setExpandedJobId(null);  // Ferme le panneau si déjà ouvert
        } else {
            setExpandedJobId(jobId);  // Ouvre le panneau pour le job sélectionné
        }
    };

    const addUser = async () => {
        try {
            const response = await fetch('https://api.wanteed-job.com/company/add-user', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': token
                },
                body: JSON.stringify({ company_id: selectedCompany.company_id, email_to_add: emailToAdd })
            });
            if (response.ok) {
                console.log('User added successfully');
            }
        } catch (error) {
            console.error('Error adding user:', error);
        }
    };

    const toggleButtons = [
        { label: 'Offres', view: 'Offres' },
        { label: 'Utilisateurs', view: 'Utilisateurs' }
    ];

    return (
        <div className={styles["admin-company-page"]}>
            <h1>Admin entreprise</h1>

            {/* Company Dropdown */}
            {companies.length > 1 && (
                <select value={selectedCompany?.company_id || ''} onChange={handleCompanySelect}>
                    <option value="" disabled>Selectionner l'entreprise</option>
                    {companies.map(company => (
                        <option key={company.company_id} value={company.company_id}>
                            {company.company_name}
                        </option>
                    ))}
                </select>
            )}

            <GenericToggleButton view={view} setView={setView} buttons={toggleButtons} />

            {view === 'Offres' && selectedCompany && (
                <>
                    {/* Search Bar and Add Job Button */}
                    <div className={styles["job-management-header"]}>
                        <input
                            type="text"
                            placeholder="Chercher une offre..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                        />
                        <button onClick={openModal} className="add-job-button">Créer un poste</button>
                    </div>

                    {isModalOpen && (
                        <div className={styles["modal-new-post"]}>
                            <div className={styles["edit-panel"]}>
                                <JobModal
                                    selectedCompany={selectedCompany}
                                    closeModal={closeModal}
                                    fetchJobs={fetchJobs}
                                />
                            </div>
                        </div>
                    )}

                    {expandedJobId && (
                        <div className={styles["modal-new-post"]}>
                            <div className={styles["edit-panel"]}>
                                <JobModal
                                    selectedCompany={selectedCompany}
                                    closeModal={closeEditModal}
                                    fetchJobs={fetchJobs}
                                    edit={true}
                                    data={jobs.find(job => job.job_id === expandedJobId)}
                                />
                            </div>
                        </div>
                    )}

                    <div className={styles["job-list"]}>
                        {filteredJobs.map(job => (
                            <div key={job.job_id} className={`${styles["job-item"]} ${expandedJobId === job.job_id ? styles["expanded"] : ""}`}>

                                {/* Afficher les informations du job uniquement si ce n'est pas le job en cours d'édition */}
                                {expandedJobId !== job.job_id && (
                                    <>
                                        <span>{job.title}</span>
                                        <a href={`/admin/job?jobId=${job.job_id}`}>
                                            <button className={styles["view-button"]}>Afficher</button>
                                        </a>
                                        <button className={styles["edit-button"]} onClick={() => toggleExpand(job.job_id)}>
                                            Editer
                                        </button>
                                        <button className={styles["delete-button"]} onClick={() => deleteJob(job.job_id)}>
                                            Supprimer
                                        </button>
                                    </>
                                )}
                            </div>
                        ))}
                    </div>
                </>
            )
            }

            {
                view === 'Utilisateurs' && (
                    <div>
                        <div className={styles["user-management"]}>
                            <h2>Utilisateurs</h2>

                            <h3>Ajouter un utilisateur à l'entreprise</h3>
                            <input type="email" placeholder="Email de l'utilisateur" onChange={(e) => setEmailToAdd(e.target.value)} />
                            <button onClick={addUser}>Ajouter</button>
                        </div>
                        <div className={styles["user-list"]}>
                            {Array.isArray(users) && users.map((user, index) => (
                                <div key={index} className={styles["user-item"]}>
                                    <span>{user.email}</span>
                                </div>
                            ))}
                        </div>
                    </div>
                )
            }
        </div >
    );
};

export default AdminCompanyPage;
