import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { getSessionToken } from '../utils/Auth';
import styles from '../styles/QuestionnaireMBTI.module.css';

const QuestionnaireMBTI = () => {
    const [question, setQuestion] = useState(null);
    const [answered, setAnswered] = useState(0);
    const [total, setTotal] = useState(0);
    const navigate = useNavigate();

    useEffect(() => {
        const token = getSessionToken();
        fetch('https://api.wanteed-job.com/questions/get-mbti', {
            method: 'GET',
            headers: {
                'session-key': `${token}`
            }
        })
            .then(response => response.json())
            .then(data => setQuestion(data));

        fetch('https://api.wanteed-job.com/questions/count-mbti', {
            method: 'GET',
            headers: {
                'session-key': `${token}`
            }
        })
            .then(response => response.json())
            .then(data => {
                setAnswered(data.answered);
                setTotal(data.total);
            });
    }, []);

    const handleAnswer = (value) => {
        fetch('https://api.wanteed-job.com/questions/answer-mbti', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'session-key': getSessionToken()
            },
            body: JSON.stringify({
                questionID: question.question_id,
                value
            })
        }).then(() => {
            if (answered + 1 === total) {
                navigate('/jobs');
            } else {
                setAnswered(answered + 1);
                fetch('https://api.wanteed-job.com/questions/get-mbti', {
                    method: 'GET',
                    headers: {
                        'session-key': getSessionToken()
                    }
                })
                    .then(response => response.json())
                    .then(data => setQuestion(data));
            }
        });
    };

    if (!question) {
        return <p>Chargement...</p>;
    }

    return (
        <div className={styles["questionnaire"]}>
            <div className={styles["test-switcher"]}>
                <button className={styles["test-button"]} onClick={() => navigate('/culturefit')}>Culture Fit</button>
                <button className={styles["test-button"]} onClick={() => navigate('/softskills')}>Soft skills</button>
                <button className={styles["test-button"]} onClick={() => navigate('/hardskills')}>Hard skills</button>
                <button className={styles["test-button-active"]} onClick={() => navigate('/mbti')}>Team fit</button>
            </div>
            <h1>Question {answered + 1} of {total}</h1>
            <div className={styles["instructions"]}>
                <p>Indiquer la situation dans laquelle vous vous identifiez le plus</p>
            </div>
            <div className={styles["questions"]}>
                <div className={styles["card"]} onClick={() => handleAnswer('A')}>
                    <p className={styles["question-left"]}>{question.question_a}</p>
                </div>
                <p>or</p>
                <div className={styles["card"]} onClick={() => handleAnswer('B')}>
                    <p className={styles["question-right"]}>{question.question_b}</p>
                </div>
            </div>
        </div>
    );
};

export default QuestionnaireMBTI;
