import React, { useState, useEffect } from 'react';
import { getSessionToken } from '../utils/Auth';
import TestSwitcher from '../components/TestSwitcher';
import styles from '../styles/CultureFitPage.module.css';

const CultureFitPage = () => {
    const [traits, setTraits] = useState([]);
    const sessionKey = getSessionToken();

    useEffect(() => {
        fetch('https://api.wanteed-job.com/questions/get-culture', {
            headers: {
                'Content-Type': 'application/json',
                'session-key': sessionKey
            }
        })
            .then(response => response.json())
            .then(data => setTraits(data))
            .catch(error => console.error('Error fetching culture traits:', error));
    }, [sessionKey]);

    const handleSliderChange = (trait, value) => {
        const updatedTraits = traits.map(t => 
            t.trait_id === trait.trait_id ? { ...t, value: value } : t
        );
        setTraits(updatedTraits);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log("traits", traits);
        console.log("object keys", Object.keys(traits));
        const promises = traits.map(trait =>
            fetch('https://api.wanteed-job.com/questions/answer-culture', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'session-key': sessionKey
                },
                body: JSON.stringify({ trait_id: trait.trait_id, value: trait.value })
            })
        );

        Promise.all(promises)
            .catch(error => console.error('Error submitting responses:', error));
    };

    return (
        <div className={styles["culture-fit-page"]}>
            <TestSwitcher activeTest="culturefit" />
            <h1 className='h1'>Culture Fit Assessment</h1>
            <form onSubmit={handleSubmit} className='form'>
                {traits.map(trait => (
                    <div key={trait.trait_id} className={styles["trait-container"]}>
                        <label className={styles["trait-label"]}>{trait.trait_name}</label>
                        <input
                            type="range"
                            min="0"
                            max="7"
                            value={trait.value}
                            onChange={(e) => handleSliderChange(trait, e.target.value)}
                            className="trait-slider"
                        />
                        <div className={styles["trait-value"]}>{traits[trait]}</div>
                    </div>
                ))}
                <button type="submit" className={styles["submit-button"]}>Submit</button>
            </form>
        </div>
    );
};

export default CultureFitPage;
